$(function () {
        const svg_next = '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M16 0a16 16 0 110 32 16 16 0 010-32zm0 2.67a13.33 13.33 0 100 26.66 13.33 13.33 0 000-26.66zm-4.55 8l1.88-1.89L20.55 16l-7.22 7.22-1.88-1.89L16.78 16l-5.33-5.33z" fill="currentColor" fill-rule="evenodd"/></svg>';
        const svg_prev = '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M16 0a16 16 0 100 32 16 16 0 000-32zm0 2.67a13.33 13.33 0 110 26.66 13.33 13.33 0 010-26.66zm4.55 8l-1.88-1.89L11.45 16l7.22 7.22 1.88-1.89L15.22 16l5.33-5.33z" fill="currentColor" fill-rule="evenodd"/></svg>';

        $('.slider-section-slider').slick({
            nextArrow: `
                <button class="slick-next slick-arrow" aria-label="Next" type="button">
                ${svg_next}        
                </button>`,
            prevArrow: `
                <button class="slick-prev slick-arrow" aria-label="Previous" type="button">
                ${svg_prev}
                </button>`,
            // infinite: true,
            // speed: 1000,
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: false,
            adaptiveHeight: 1,
            // lazyLoad: 'ondemand',
            // autoplay: true,
            // autoplaySpeed: 2000
        });
});