$(function () {
  
  var currentSlideIndex = 0;
  var gallery = $('.gallery');
  var imagesContainer = gallery.find('.gallery__carousel-images');
  var images = imagesContainer.children('.gallery__carousel-image');
  var arrowLeft = gallery.find('.gallery__carousel-arrow-left');
  var arrowRight = gallery.find('.gallery__carousel-arrow-right');
  var indicators = gallery.find('.carousel__indicator');
  
  // Define the click handlers
  arrowLeft.click(function () {
    var index = getPrevSlideIndex();
    displayImage(index)
    updateDots(index);
  });
  arrowRight.click(function () {
    var index = getNextSlideIndex();
    displayImage(index)
    updateDots(index);
  });
  indicators.click(function () {
    var index = $(this).index();
    displayImage(index)
    updateDots(index);
  });
  
  $(window).resize(function () {
    displayImage(currentSlideIndex);
  });
  
  function getNextSlideIndex() {
    if (currentSlideIndex === images.length - 1) {
      return 0;
    }
    
    return currentSlideIndex + 1;
  }
  
  function getPrevSlideIndex() {
    if (currentSlideIndex === 0) {
      return images.length - 1;
    }
    
    return currentSlideIndex - 1;
  }
  
  // Applies the correct 'left' property to the images container
  // to provide a sliding carousel effect.
  function displayImage(index) {
    var width = imagesContainer.width();
    imagesContainer.css('left', -(width * index));
    currentSlideIndex = index;
  }
  
  // Updates the 'selected' class for the dots.
  function updateDots(index) {
    indicators.removeClass('carousel__indicator--selected');
    $(indicators.get(index)).addClass('carousel__indicator--selected');
  }
  
});

// Ribbon style, made for M7 client
$(function () {

  if ($('.gallery-slick-ribbon').length){
    
    const svg_next = '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M16 0a16 16 0 110 32 16 16 0 010-32zm0 2.67a13.33 13.33 0 100 26.66 13.33 13.33 0 000-26.66zm-4.55 8l1.88-1.89L20.55 16l-7.22 7.22-1.88-1.89L16.78 16l-5.33-5.33z" fill="currentColor" fill-rule="evenodd"/></svg>';
    const svg_prev = '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M16 0a16 16 0 100 32 16 16 0 000-32zm0 2.67a13.33 13.33 0 110 26.66 13.33 13.33 0 010-26.66zm4.55 8l-1.88-1.89L11.45 16l7.22 7.22 1.88-1.89L15.22 16l5.33-5.33z" fill="currentColor" fill-rule="evenodd"/></svg>';
  
    $('.gallery-slick-ribbon').slick({
      nextArrow: `
        <button class="slick-next slick-arrow" aria-label="Next" type="button">
          ${svg_next}        
        </button>`,
      prevArrow: `
        <button class="slick-prev slick-arrow" aria-label="Previous" type="button">
          ${svg_prev}
        </button>`,
      infinite: true,
      speed: 1000,
      slidesToShow: 3,
      slidesToScroll: 1,
      variableWidth: true,
      lazyLoad: 'ondemand',
      autoplay: true,
      autoplaySpeed: 2000
    });
  }
  
});