$ = window.jQuery;
// import './cookies';
import '../scss/main.scss';
// import PhotoSwipe from 'photoswipe/dist/photoswipe';
// import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

// import '../../../../../../webroot/themes/all/js/map';

// import { Foundation } from 'foundation-sites/js/foundation.core.js';

// import { Magellan } from 'foundation-sites/js/foundation.magellan.js';
// Foundation.plugin(Magellan, 'Magellan');

// import { Reveal } from 'foundation-sites/js/foundation.reveal.js';
// Foundation.plugin(Reveal, 'Reveal');

// import { SmoothScroll } from 'foundation-sites/js/foundation.smoothScroll.js';
// Foundation.plugin(SmoothScroll, 'SmoothScroll');

// import { Triggers } from 'foundation-sites/js/foundation.util.triggers';
// Triggers.init($, Foundation);

Foundation.addToJquery($);

import './map';
import './mobile-nav';
import './more-nav';
import './section-header';
// import './header';
import './gallery-carousel';
import './parcels-carousel';
import './availability-carousel';
import './virtualtour';
import './banner-main-portfolio';
import './slider';

import AOS from 'aos';
AOS.init();

$(function () {
  $(document).foundation();
  $('body').removeClass('is-loading');

  // Add class to body if page is loaded within an iframe
  // Currently needed for hero height vh fix for iOS
  if ( window.location !== window.parent.location ) {
    $('body').addClass('within-iframe');
  }
  if (navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)/i)){
    $('body').addClass('ios');
  }

  // Some cases AOS runs too soon and needs recalc at bottom of page
  setTimeout(AOS.refresh, 4000);

});

// import './exit-intent';
